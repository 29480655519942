import {Box} from '@mui/material';
import './loading.css';

const LoadingAnimation = () => {
    return <Box
        sx={{
            display: "flex",
            justifyContent: "center",
        }}
    >
        <div className="spinner">
            <span>L</span>
            <span>O</span>
            <span>A</span>
            <span>D</span>
            <span>I</span>
            <span>N</span>
            <span>G</span>
        </div>
    </Box>
}
export default LoadingAnimation
