import {Box, IconButton, Typography} from '@mui/material';
import {Fastfood} from '@mui/icons-material';
import {appBarColor} from './Constants';

const PlaceCardActionItem = (props) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            id={props.id}
            onClick={() => props.action()}
        >
            <IconButton >
                {props.icon}
            </IconButton>
            <Typography sx={{fontSize: 13}} color={appBarColor}>
                {props.title}
            </Typography>
        </Box>
    )
}
export default PlaceCardActionItem;
