import {createSlice} from '@reduxjs/toolkit'
import {supabase} from '../../supabaseClient';

const initialState = {
    categories: [],
    filteredCategories: [],
    tourCategories: [],
    selectedCategory: 'All',
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setCategories(state, action) {
            state.categories = action.payload;
        },
        setFilteredCategories(state, action) {
            state.filteredCategories = action.payload;
        },
        setTourCategories(state, action) {
            state.tourCategories = action.payload;
        },
        setSelectedCategory(state, action) {
            state.selectedCategory = action.payload;
        },
    }
})

export const {
    setCategories,
    setFilteredCategories,
    setTourCategories,
    setSelectedCategory
} = categorySlice.actions

export const getCategories = () => async dispatch => {
    const {data, error} = await supabase.from('category')
        .select(
            `
            id,
            name
       `
        )
        .order('name')
    dispatch(setCategories(data))
}


export default categorySlice.reducer
