import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'intro.js/introjs.css';
import reportWebVitals from './reportWebVitals';
import Main from './Main';
import {Provider} from 'react-redux';
import store from "./redux/store";
import * as serviceWorker from './serviceWorker';
import {HelmetProvider} from 'react-helmet-async';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <Main/>
            </HelmetProvider>
        </Provider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorker.register();


