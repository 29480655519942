import {configureStore} from '@reduxjs/toolkit'
import navReducer from './reducers/navSlice'
import placesReducer from './reducers/placesSlice'
import categoriesReducer from './reducers/categorySlice'
import authReducer from './reducers/authSlice'

export const store = configureStore({
    reducer: {
        nav: navReducer,
        places: placesReducer,
        categories: categoriesReducer,
        auth: authReducer
    }
})

export default store
